

import { Component, Watch, Prop } from 'vue-property-decorator';
import ImportComponent from '../components/ImportComponent.vue';
import ProjectHelper from '../components/ProjectHelper';
import { Project } from '../store/models';

@Component
export default class ImportProject extends ImportComponent {

  private helper: ProjectHelper = new ProjectHelper();

  protected isFieldsLoaded(): boolean {
    return (!this.helper.isLoading);
  }

  protected mounted() {
    this.loadSchemas();
    this.helper.populateDataLists(this.currentOrg);
  }

  protected getConvertedJsonRecord(record: any): any {
    const project: Project = {
      project: {
        description: record.description,
        projectId: record.projectId,
        projectName: record.projectName,
        ownerVendor: this.currentOrg,
        creatorVendor: record.creatorVendor ? record.creatorVendor : '',
        hasSubmitter: this.currentUser.userinfo.identifier,
        startDate: this.toIsoDateString(record.startDate),
        indigenousAffiliation: record.indigenousAffiliation ? record.indigenousAffiliation : '',
        impactTargets: {
          indigenousProjectBenefit: 0,
          indigenousCommunityContribution: 0,
          indigenousEmployment: 0,
          socialProcurement: 0,
          diverseProjectBenefit: 0,
          diverseCommunityContribution: 0,
          diverseEmployment: 0,
        },
      },
      purchaseOrder: {
        poAmount: this.cleanseCurrencyField(record.poAmount),
        poCurrency: 'CAD',
        poDate: this.toIsoDateString(record.startDate),
        poId: record.poId,
      },
      properties: {
        province: record.province ? record.province : '',
        discipline: record.discipline ? this.getCategoryRefDataCodeFromCombinedValues(record.discipline as string) : '',
      },
    };
    // console.log(JSON.stringify(project));
    return project;
  }

  protected getSchemas(): string[] {
    return [ 'general', 'create', 'projectGeneral' ];
  }

  protected getFields(): any[] {
    return [
      this.getField('description', 'Description'),
      this.getField('poAmount', 'Amount'),
      this.getField('poId', 'PO Id'),
      this.getField('creatorVendor', 'Client Name', this.getOptionsFromShortRecords(this.helper.vendors)),
      this.getField('discipline', 'Discipline', this.getOptionsFromCategoryRefData(this.helper.contractingTypes)),
      this.getField('projectId', 'Project ID'),
      this.getField('projectName', 'Project Name'),
      this.getField('province', 'Province', this.getOptionsFromRefData(this.helper.provinces)),
      this.getField('startDate', 'Start Date'),
      this.getField('indigenousAffiliation', 'Indigenous Project Partner', this.getOptionsFromShortRecords(this.helper.ics)),
    ];
  }

  protected getType(): string {
    return 'Project';
  }

  protected getSchemaType(): string {
    return 'project';
  }

  @Watch('helper.isLoading')
  private onHelperIsLoadingChanged(val: any, oldVal: any) {
    this.launchIfLoaded();
  }
}
