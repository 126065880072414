import { getShortList, getReferenceData, getCategoryReferenceData, getPartnerContacts, getContacts, getProject } from '../store/rest.service';
import { ResourceSearchResult, ReferenceData, ShortRecord, CategoryReferenceData, Project } from '../store/models';
import { handleError } from '../lib/shared';

export default class ProjectHelper {

  public arrErrors: Error[] = [];
  public isLoading: boolean = true;

  public projects: ShortRecord[]|null = null;
  public projectsLoading: boolean = false;

  public ics: ShortRecord[]|null = null;
  public icsLoading: boolean = false;

  public vendors: ShortRecord[]|null = null;
  public vendorsLoading: boolean = false;

  public provinces: ReferenceData|null = null;
  public provincesLoading: boolean = false;

  public contractingTypes: CategoryReferenceData|null = null;
  public contractingCategories: ReferenceData|null = null;
  public contractingTypesLoading: boolean = false;
  public contractingTypesConverted: any = [];

  public partnerContacts: ResourceSearchResult|null = null;
  public partnerContactsLoading: boolean = false;

  public contacts: ResourceSearchResult|null = null;
  public contactsLoading: boolean = false;

  public project: Project|null = null;
  public projectLoading: boolean = false;

  public isSubProject: boolean = false;

  constructor(isSubProject: boolean = false) {
    this.isSubProject = isSubProject;
  }

  public setIsLoading(): void {
    this.isLoading = (
      this.vendorsLoading
      || this.provincesLoading
      || this.contractingTypesLoading
      || this.icsLoading
      || this.projectsLoading
      || this.contactsLoading
      || this.projectLoading
    );
  }

  public populateDataLists(currentOrg: string) {
    this.getProjects(currentOrg);
    this.getContractingTypeReferenceData();
    this.getVendors(currentOrg);
    this.getIndigenousCommunities();
    this.getContacts();
    if (!this.isSubProject) {
      this.getProvinceReferenceData();
    }
  }

  public getIndigenousCommunities() {
    if (!this.ics) {
      this.icsLoading = true;
      this.setIsLoading();
      getShortList('IndigenousCommunity', false, [], true)
      .then((response) => {
        this.ics = response.searchResults.results as ShortRecord[];
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.icsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getContractingTypeReferenceData() {
    if (!this.contractingTypes) {
      this.contractingTypesLoading = true;
      this.setIsLoading();
      this.contractingTypesConverted = [];
      getReferenceData('contracting-categories').then((response) => {
        this.contractingCategories = response;
        getCategoryReferenceData('contracting-types').then((response2) => {
          this.contractingTypes = response2;
          for (const category of (this.contractingCategories as ReferenceData).refData.values) {
            const children: any = [];
            for (const contractingType of this.contractingTypes.refData.values) {
              if (contractingType.categoryCode === category.code) {
                const item = {
                  id: category.code + ';' + contractingType.code,
                  label: contractingType.name,
                  category: contractingType.category,
                };
                children.push(item);
              }
            }
            const categoryItem = {
              id: category.code,
              label: category.name,
              children,
            };
            this.contractingTypesConverted.push(categoryItem);
          }
        }).catch((error) => {
          handleError(this.arrErrors, error);
        }).finally(() => {
          this.contractingTypesLoading = false;
          this.setIsLoading();
        });
      }).catch((error) => {
        handleError(this.arrErrors, error);
      });
    }
  }

  public getProvinceReferenceData() {
    if (!this.provinces) {
      this.provincesLoading = true;
      this.setIsLoading();
      getReferenceData('province')
      .then((response) => {
        this.provinces = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.provincesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getVendors(currentOrg: string, force: boolean = false) {
    if (!this.vendors || force) {
      this.vendorsLoading = true;
      this.setIsLoading();
      // const whereClauses: Array<[string, string[]]> = [ [ 'has-provisional-creator', [ currentOrg ] ] ];
      // getShortList('Vendor', false, whereClauses).then((response) => {
      getShortList('Vendor').then((response) => {
        //Filter out my own vendor.
        response.searchResults.results.forEach((item: any, index: any) => {
            if ((item as ShortRecord).identifier === currentOrg) {
              response.searchResults.results.splice(index, 1);
            }
          });
        this.vendors = (response as ResourceSearchResult).searchResults.results as ShortRecord[];
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.vendorsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getProjects(currentOrg: string, force: boolean = false) {
    if (!this.projects || force) {
      this.projectsLoading = true;
      this.setIsLoading();
      const whereClauses: Array<[string, string[]]> = [ [ 'project.ownerVendor', [ currentOrg ] ] ];
      getShortList('Project', false, whereClauses, false, 'poId')
      .then((response) => {
        this.projects = (response as ResourceSearchResult).searchResults.results as ShortRecord[];
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.projectsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getPartnerContacts(partnerId: string) {
    this.partnerContactsLoading = true;
    this.partnerContacts = null;
    this.setIsLoading();
    getPartnerContacts(partnerId)
    .then((response) => {
      this.partnerContacts = (response as ResourceSearchResult);
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.partnerContactsLoading = false;
    });
  }

  public getContacts() {
    this.contactsLoading = true;
    this.contacts = null;
    this.setIsLoading();
    getContacts()
    .then((response) => {
      this.contacts = (response as ResourceSearchResult);
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.contactsLoading = false;
      this.setIsLoading();
    });
  }

  public getProject(id: string) {
    this.projectLoading = true;
    this.setIsLoading();
    getProject(id, false).then((response) => {
      this.project = response;
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.projectLoading = false;
      this.setIsLoading();
    });
  }

  public handleError(error: any) {
    handleError(this.arrErrors, error);
  }
}
